<!-- CommercialService.vue -->
<!-- Presents a list of the commercial services available -->


<template>

  <!-- ********************************************** -->
  <!-- HEADER SECTION -->
  <!-- ********************************************** -->
  <v-row>
    <v-col>
      <v-row>
        <v-col :offset="$vuetify.breakpoint.xs ? 0 : 1" :cols="$vuetify.breakpoint.xs ? 12 : 10">

          <!-- Title -->
          <v-card-text primary-title class="text-title mb-0 px-0" :class="headerstore ? 'text-left' : 'text-center'">
            {{ i18n.t('chooseYourCommercialService') }}
          </v-card-text>

        </v-col>
      </v-row>


      <!-- "Back" Button -->
      <v-col class="pa-0 min-height-44 d-flex align-center" offset="1" cols="10">
        <BackBtn></BackBtn>
        <icon class="flex-grow-1" svg='step-marker-2' />
      </v-col>

      <!-- Some Alert -->
      <v-col class="pa-0 d-flex align-center" offset="1" cols="10">
        <AlertBox></AlertBox>
      </v-col>


      <!-- ********************************************** -->
      <!-- Commercial Services Button -->
      <!-- ********************************************** -->

      <v-form ref="wasteForm" v-model="formValid" lazy-validation>

        <!-- Carousel to display all services available -->
        <v-carousel height="100%" :hide-delimiters="true" hide-delimiter-background :show-arrows="false"
          :continuous="false">

          <v-col offset="1" cols="10" class="px-0">
            <v-carousel-item v-for="(slide, i) in slideshow" :key="i">
              <v-list>

                <v-list-item-group transition="scroll-x-transition" v-model="form.menuNavigationId"
                  @change="onChangeServiceList()">

                  <v-list-item v-slot="{ active }" class="blue-default-svg rounded-lg my-4 py-4"
                    v-for="(item, i) in slide" :key="i" :value="item.name" height="85px" v-bind:disabled="loading"
                    v-ripple="loading ? false : true" active-class="blue-active-svg"
                    @click="form.categoryType = item.text; form.svgCategorySelected = item.icon;">

                    <!-- Icons -->
                    <icon v-if="!active" contain width="50px" max-height="51px" :svg="item.icon"
                      class="blue-svg ml-4" />

                    <icon v-if="active" contain width="50px" max-height="51px" :svg="item.icon"
                      class="white-svg ml-4" />


                    <!-- ********************************************** -->
                    <!-- Content in Commercial Button -->
                    <!-- ********************************************** -->
                    <v-list-item-content>

                      <!-- Text with traduction -->
                      <v-list-item-title class="text-h6 pl-6 pr-3" :class="active ? 'white--text' : 'black--text'">{{
                        i18n.locale === 'fr' ? item.textFr : item.text }}</v-list-item-title>
                    </v-list-item-content>

                    <!-- Icon -->
                    <icon v-if="active" contain max-width="76px" max-height="71px" svg="checkmark" />
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-carousel-item>
          </v-col>
        </v-carousel>


        <!-- ********************************************** -->
        <!-- Message d'erreur -->
        <!-- ********************************************** -->

        <v-row v-if="!formValid && !this.form.menuNavigationId" class="my-3">
          <div class="v-messages error--text px-6 text-center" role="alert">
            <div class="subtitle-2">
              <v-icon class="error--text" left="left" dense="dense">mdi-alert-outline</v-icon> {{ i18n.t('missingField')
              }}
            </div>
          </div>
        </v-row>

      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { useUserStore } from "../../../../store/user";
import i18n from "@/i18n";
import icon from "../../utility/SvgLoader";
import { useIndexStore } from '../../../../store/index';
import { mapState, mapWritableState } from 'pinia';
import { mapActions } from 'pinia';
import BackBtn from "../../utility/backBtn.vue"
import AlertBox from "../../../sfc/Alert.vue"
import googleApi from "@/plugins/google"

export default {
  name: "CommercialService",
  components: { icon, BackBtn, AlertBox },
  data() {
    return {
      i18n: i18n,
      slideshow: { // needed in the carousel
        serviceOptions: [
          {
            id: 1,
            name: "WasteRecyPickUp",
            apiName: "WasteAndRecycling",
            text: i18n.t('wasteAndRecyclingPickup', 'en'),
            textFr: i18n.t('wasteAndRecyclingPickup', 'fr'),
            icon: "WasteRecyPickUp",
          },
          {
            id: 2,
            name: "WasteRecyPickUp",
            apiName: "WasteOnly",
            text: i18n.t("wasteOnly", "en"),
            textFr: i18n.t("wasteOnly", "fr"),
            icon: "WasteRecyPickUp",
          },
          {
            id: 3,
            name: "WasteRecyPickUp",
            apiName: "RecyclingOnly",
            text: i18n.t("recyclingOnly", "en"),
            textFr: i18n.t("recyclingOnly", "fr"),
            icon: "WasteRecyPickUp",
          },
          {
            id: 4,
            name: "OrganicWaste",
            apiName: "Organics",
            text: i18n.t("organics", "en"),
            textFr: i18n.t("organics", "fr"),
            icon: "OrganicWaste",
          },
          {
            id: 5,
            name: "ShreddingView",
            apiName: "Shredding",
            text: i18n.t("shredding", "en"),
            textFr: i18n.t("shredding", "fr"),
            icon: "Shredding",
          },
          {
            id: 6,
            name: "DumpsterRental",
            apiName: "DumpsterRental",
            text: i18n.t('dumpsterRentalConstructionBulkyPickup', 'en'),
            textFr: i18n.t('dumpsterRentalConstructionBulkyPickup', 'fr'),
            icon: "DumpsterRental",
          },
          {
            id: 7,
            name: "MhoamView",
            apiName: "ApartmentsAndCondos",
            text: i18n.t('apartmentsCondosHOAs', 'en'),
            textFr: i18n.t('apartmentsCondosHOAs', 'fr'),
            icon: "MhoamView",
          },
          {
            id: 8,
            name: "MunicipalitiesView",
            apiName: "Municipalities",
            text: i18n.t('municipalities', 'en'),
            textFr: i18n.t('municipalities', 'fr'),
            icon: "Municipalities",
          },
          {
            id: 9,
            name: "PortableToilets",
            apiName: "PortableToilet",
            text: i18n.t('portableToilets', 'en'),
            textFr: i18n.t('portableToilets', 'fr'),
            icon: "PortableToilets",
          },
          {
            id: 10,
            name: "SomethingElse",
            apiName: "SomethingElse",
            text: i18n.t('somethingElse', 'en'),
            textFr: i18n.t('somethingElse', 'fr'),
            icon: "question",
          },
        ],
        defaultServiceOptions: [
          {
            id: 1,
            name: "WasteRecyPickUp",
            apiName: "WasteAndRecycling",
            text: i18n.t('wasteAndRecyclingPickup', 'en'),
            textFr: i18n.t('wasteAndRecyclingPickup', 'fr'),
            icon: "WasteRecyPickUp",
          },
          {
            id: 2,
            name: "DumpsterRental",
            apiName: "DumpsterRental",
            text: i18n.t("dumpsterRental", "en"),
            textFr: i18n.t("dumpsterRental", "fr"),
            icon: "DumpsterRental",
          },
          {
            id: 3,
            name: "MhoamView",
            apiName: "ApartmentsAndCondos",
            text: i18n.t("apartmentsCondosHOAs", "en"),
            textFr: i18n.t("apartmentsCondosHOAs", "fr"),
            icon: "MhoamView",
          },
          {
            id: 4,
            name: "MunicipalitiesView",
            apiName: "Municipalities",
            text: i18n.t("municipalities", "en"),
            textFr: i18n.t("municipalities", "fr"),
            icon: "Municipalities",
          },
          {
            id: 5,
            name: "SomethingElse",
            apiName: "SomethingElse",
            text: "Something Else?",
            subtitle: "Send us a message",
            icon: "question",
          }

        ],
      },
    };
  },

  // * MOUNTED : Filtered service options based on the adresse
  mounted: function () {
    if (this.servicesofferedComm) {
      this.slideshow = this.filterSlideshowByServices(this.slideshow, this.servicesofferedComm);
    } else if (this.apiResult?.data?.RequestQuoteSetting?.Commercial) {
      this.updateServiceOptions('Commercial');
    } else {
      this.setDefaultServiceOptions();
    }
  },
  computed: {
    // Map state properties as readonly computed properties
    ...mapState(useIndexStore, [
      "showGetAQuote",
      "showGoToStore",
      "headerstore",
      "servicesofferedComm",
    ]),
    ...mapWritableState(useIndexStore, [
      'formValid',
      'loading',
      'apiResult',
    ]),
    ...mapWritableState(useUserStore, [
      "form",
    ]),
  },
  methods: {
    ...mapActions(useIndexStore, [
      'back',
      'updateStages',
      'updateViewComponent',
      'getLeadTypeFromServiceType',
    ]),
    filterEnabledServices(settingType) {
      const settings = this.apiResult.data?.RequestQuoteSetting?.[settingType] || {};
      return this.slideshow.serviceOptions.filter(service => settings[service.apiName]);
    },
    setDefaultServiceOptions() {
      this.slideshow.serviceOptions = this.slideshow.defaultServiceOptions;
    },
    filterSlideshowByServices(slideshow, services) {
      const seen = new Set(); // To track already added items

      return Object.fromEntries(
        Object.entries(slideshow).map(([key, items]) => [
          key,
          items.filter(item => {
            if (services.includes(item.name) && !seen.has(item.name)) {
              seen.add(item.name); // Add to seen to skip subsequent occurrences
              return true;
            }
            return false;
          })
        ])
      );
    },
    updateServiceOptions(settingType) {
      let filteredServices = this.filterEnabledServices(settingType);

      // Ensure "Something Else" is always included
      const somethingElse = this.slideshow.serviceOptions.find(item => item.name === "SomethingElse");
      if (somethingElse && !filteredServices.some(item => item.name === "SomethingElse")) {
        filteredServices.push(somethingElse);
      }

      // Update service options or set to default if none available
      this.slideshow.serviceOptions = filteredServices.length ? filteredServices : this.slideshow.defaultServiceOptions;
    },
    onChangeServiceList() {
      const leadType = this.getLeadTypeFromServiceType(this.form.serviceType, this.form.categoryType).toLowerCase();
      const gTagParams = [leadType, 0, this.showGetAQuote ? "Display Splitter View" : "Display Lead Field", process.env.VUE_APP_GTAG_LEAD_CATEGORY, this.getLeadTypeFromServiceType(this.form.serviceType, this.form.categoryType), this.form.categoryType, this.apiResult.districtID];

      if (this.showGetAQuote && this.showGoToStore) {
        googleApi.gTagSendEvents(...gTagParams);
        this.updateViewComponent('CommStorefront');
        this.form.menuOrder.push("CommStorefront");
        this.form.webLeadStep = "Redirect Stage";
      } else if (!this.showGetAQuote && this.showGoToStore) {
        googleApi.gTagSendEvents(...gTagParams);
        this.updateViewComponent('CommStorefront');
        this.form.menuOrder.push("CommStorefront");
        this.form.webLeadStep = "Redirect Stage";
      } else {
        googleApi.gTagSendEvents(...gTagParams);
        this.updateStages("Redirect Stage");
      }
    },
  },
};
</script>

<style scoped>
.min-height-44 {
  min-height: 44px;
}
</style>
